<!--
 * @Author: dongjia
 * @Date: 2021-08-09 10:30:03
 * @LastEditTime: 2022-01-18 14:52:22
 * @LastEditors: aleaner
 * @Description: 业务组件数据选择弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Normal\dataSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="`选择${dialogTitle}数据`"
    :visible.sync="openDialog"
    width="800px"
    :show-close="true"
    class="dialog-vertical"
    append-to-body
  >
    <div class="dialog-content">
      <div class="data-list-header">
        <div class="header-top">
          <div class="header-btn-group">
            <el-button class="item-btn" size="small" @click="refreshList"
              >刷新</el-button
            >
          </div>
          <div class="header-filter">
            <template>
              <el-input
                style="width: 160px"
                v-model="filterForm.keyword"
                :placeholder="`搜索${dialogTitle}名称`"
                @change="handleFilter"
                size="small"
              >
                <i class="el-icon-search el-input__icon" slot="suffix"></i>
              </el-input>
            </template>
          </div>
        </div>
        <div class="header-bottom">
          <div class="header-filter"></div>
        </div>
        <el-table
          class="thead-light"
          :data="dataList"
          style="width: 100%; cursor: pointer; margin-top: 10px"
          :height="340"
          ref="multipleTable"
          @row-click="handleCurrentChange"
          @selection-change="handleSelectionChange"
          v-loading="loading"
          v-loadmore="LoadMore"
        >
          <!-- 单选 -->
          <el-table-column label width="35">
            <template slot-scope="scope">
              <el-radio :label="scope.row.id" v-model="selectId"
                >&nbsp;</el-radio
              >
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column
              show-overflow-tooltip
              :prop="th.prop"
              :label="th.label"
              :min-width="th.minWidth || ''"
              :key="index"
            >
              <template slot-scope="scope">
                <div class="status-text" v-if="th.type === 'status_text'">
                  <div
                    class="status-dot"
                    :style="{
                      backgroundColor: scope.row[th.status_text_field],
                    }"
                  ></div>
                  <div>{{ scope.row[th.prop] | placeholder }}</div>
                </div>
                <list-image
                  :width="80"
                  :height="45"
                  fit="cover"
                  v-else-if="
                    scope.row[th.prop] &&
                    (th.prop === 'cover' ||
                      th.prop === 'image' ||
                      th.prop === 'icon' ||
                      th.prop === 'avatar')
                  "
                  :src="scope.row[th.prop]"
                ></list-image>
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
    <span slot="footer">
      <el-button class="small" @click="openDialog = false">取消</el-button>
      <el-button class="small" type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dataList } from "../../api/activity-list/detail";
import StChannelsCategoryFilter from "@/modules/app-design/components/Form/StChannelsCategoryFilter";
import ListImage from "@/base/components/List/ListImage";
import debounce from "lodash/debounce";
export default {
  name: "dataSelector",
  components: { ListImage, StChannelsCategoryFilter },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Array, Object],
      default: () => [],
    },
    componentType: {
      type: String,
      default: "st-activity-photo",
    },
  },
  data() {
    return {
      loading: false, //加载
      dialogTitle: "云相册",
      thead: [],
      dataList: [],
      //筛选对象
      filterForm: {
        category_id: -1,
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
        component: this.$props.componentType,
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      categoryList: [],
      selectArr: [], //勾选的数组
      handleAddRouterName: "",
      selectId: -1,
    };
  },
  watch: {
    dataList: {
      deep: true,
      handler(val) {
        if (this.filterForm.page === 1) {
          this.dataList.forEach((el) => {
            if (
              this.selected.findIndex((elem) => elem.id === el.id) !== -1 &&
              this.$refs.multipleTable
            ) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(el, true);
              });
              this.$nextTick(() => {
                this.$refs.multipleTable.doLayout();
              });
            }
          });
        } else {
          this.dataList.forEach((el) => {
            if (
              this.selectArr.findIndex((elem) => elem.id === el.id) !== -1 &&
              this.$refs.multipleTable
            ) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(el, true);
              });
              this.$nextTick(() => {
                this.$refs.multipleTable.doLayout();
              });
            }
          });
        }
      },
    },
    openDialog(val) {
      if (val) {
        this.dataList.forEach((el) => {
          if (this.selected.findIndex((elem) => elem.id === el.id) !== -1) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true);
            });
          }
        });

        this.getDataList(this.filterForm);
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  created() {},
  methods: {
    // 获取数据列表
    getDataList(pageData) {
      // 是否可以请求列表
      if (this.getable) {
        this.loading = true;
        console.log(pageData);

        dataList(pageData)
          .then((res) => {
            try {
              const { data } = res;
              this.handleAddRouterName = data.button_new;
              this.handleAdminRouterName = data.button_manage;
              this.dialogTitle = data.name;
              if (this.componentType === "st-activity-photo") {
                this.thead = [
                  { label: "封面图", prop: "cover" },
                  { label: "相册名称", prop: "activity_name" },
                  { label: "数据类型", prop: "type_text" },
                  { label: "图片数量", prop: "photo_image_count" },
                  { label: "分类名称", prop: "category_name" },
                ];
              } else {
                this.thead = data.table;
              }
              if (pageData.page === 1) {
                this.dataList = data.list.data;
              } else {
                this.dataList = [...this.dataList, ...data.list.data];
              }
              if (data.list.data.length < this.filterForm.page_size) {
                this.getable = false;
              }
              this.filterForm.page++;
            } catch (e) {
              console.log(e);
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const filterData = { ...this.filterForm };
      filterData.page = key ? filterData.page : 1;
       if(!key) {
        this.filterForm.page = 1
      }
      this.getable = true;
      this.getDataList(filterData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        this.selectArr.push(selection[selection.length - 1]);
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.dataList.forEach((element) => {
        if (val.id === element.id) {
          this.selectId = val.id
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(element);
          });
        }
      });
    },
    //触底刷新
    LoadMore: debounce(function () {
      if (this.getable && this.dataList.length >= 10) {
        this.handleFilter(this.filterForm, "Load");
      }
    }, 200),
    // 刷新
    refreshList() {
      this.getable = true;
      this.handleFilter({ ...this.filterForm, page: 1 });
    },
    // 点击新增
    handleAdd() {
      let routerData = this.$router.resolve({
        name: this.handleAddRouterName,
        params: { id: 0, type: "add" },
      });
      window.open(routerData.href, "_blank");
    },
    // 点击管理
    handleAdmin() {
      let routerData = this.$router.resolve({
        name: this.handleAdminRouterName,
      });
      window.open(routerData.href, "_blank");
    },
    // 确定
    submit() {
      if (this.selectId === -1) {
        this.$message.info("请选择数据项");
      } else {
        // 返回选中的id相册
        let resultItem = this.dataList.filter((item, i) => {
          return item.id === this.selectId;
        });
        this.$emit("change", resultItem);
        console.log(resultItem);
        this.openDialog = false;
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-list-header {
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-btn-group {
    }
    .header-filter {
      .el-input + .el-input {
        margin-left: 10px;
      }

      .el-select + .el-input {
        margin-left: 10px;
      }
    }
    .header-filter + .header-filter {
      margin-left: 10px;
    }
  }
}
.data-list {
  margin-top: 20px;
}
.status-text {
  display: flex;
  align-items: center;
  .status-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
